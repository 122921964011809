import * as React from 'react';
import config from 'config';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { I18nType, TranslateInterface } from 'utils/translation';
import { languageManager } from 'services/culture';
import { SubscriptionGiftOrderFormValues } from 'services/page-subscription-gifts';
import { StripeFormWrapper } from './StripeFormWrapper';

export type StripePropsGiftProps = {
  purchaseAsGift: true;
  giftPayload: SubscriptionGiftOrderFormValues;
};

type StripePropsPurchaseProps = {
  purchaseAsGift: false;
};

type StripePropsFormValueProps = StripePropsGiftProps | StripePropsPurchaseProps;

type StripeProps = StripePropsFormValueProps &
  TranslateInterface & {
    i18n: I18nType;
    slug: string;
    nextStep: () => void;
  };

export const Stripe = ({ i18n, slug, t, nextStep, ...rest }: StripeProps): JSX.Element => {
  return (
    <StripeProvider apiKey={config.stripe.apiKey}>
      <Elements locale={languageManager.getLanguage(i18n)}>
        <StripeFormWrapper t={t} slug={slug} nextStep={nextStep} {...rest} />
      </Elements>
    </StripeProvider>
  );
};
