import ClassNames from '@streamloots/classnames';
import { PaymentMethods } from '@streamloots/streamloots-types';
import { useTranslation } from 'utils/translation';
import { PaymentMethodRadio } from './PaymentMethodRadio';
import theme from './payment-methods.scss';

export interface PaymentMethodsProps {
  className?: string;
  paymentMethods?: PaymentMethods[];
  selectedMethod?: PaymentMethods;
  onChange: (selectedMethod: PaymentMethods) => void;
}

const classNames = ClassNames(theme);

export const PaymentMethodRadios = ({
  className = '',
  paymentMethods = Object.values(PaymentMethods),
  selectedMethod = PaymentMethods.PAYPAL,
  onChange,
}: PaymentMethodsProps): JSX.Element | null => {
  const { t } = useTranslation('checkout');

  if (paymentMethods.length === 0) {
    return null;
  }

  return (
    <div className={classNames({ 'payment-methods': true, [className]: Boolean(className) })}>
      {paymentMethods.map(processor => (
        <PaymentMethodRadio
          key={processor}
          processor={processor}
          selected={processor === selectedMethod}
          onChange={onChange}
          t={t}
        />
      ))}
    </div>
  );
};
