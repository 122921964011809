import actionCreator from 'helpers/actionCreator';
import { SuccessBindingParams } from 'types/indexTS';
import type { CollectionLootOpenedActionError, CollectionLootOpenedActionSuccess, OpenLootParams } from './types';
import { errorMapping } from './errorMapping';
import endpoints from '../endpoints';
import { UserSetLootsActionTypes } from './actionTypes';
import { normalizeResponse } from './normalizer';
import { CollectionLootOpenedResponse } from './types';

const lootOpened = ({
  additionalData,
  data,
}: SuccessBindingParams<CollectionLootOpenedResponse>): CollectionLootOpenedActionSuccess =>
  actionCreator.createAction(UserSetLootsActionTypes.OPEN_SUCCESS, normalizeResponse(data), {
    setId: additionalData.setId,
  });

const lootNotOpened = ({ additionalData, errorData, urlParameters }): CollectionLootOpenedActionError =>
  actionCreator.createErrorAction(UserSetLootsActionTypes.OPEN_ERROR, errorData, errorMapping, {
    ...additionalData,
    ...urlParameters,
  });

const openLoot = ({ setId, collectionId, amount }: OpenLootParams) =>
  actionCreator.createAsyncAction({
    additionalData: {
      setId,
    },
    endpoint: endpoints.LOOTS.OPEN,
    errorBinding: lootNotOpened,
    loadingAction: () => actionCreator.createAction(UserSetLootsActionTypes.OPEN_REQUEST, { setId }),
    urlParameters: { collectionId, setId },
    parameters: {
      amount,
    },
    method: 'post',
    successBinding: lootOpened,
  });

export const userSetLootsActions = {
  openLoot,
};
