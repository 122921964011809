import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { SubscriptionCommunityGift } from 'model/indexTS';
import { GiftSubscriptionType } from '@streamloots/streamloots-types';
import { SubscriptionGiftsActionTypes } from './actionTypes';
import { errorMapping } from './errorMapping';
import {
  SubscriptionGiftsActionError,
  SubscriptionGiftsActionSuccess,
  ClaimSubscriptionGift,
  ClaimSubscriptionGiftActionError,
  CreateSubscriptionGifOrderParams,
  CreateSubscriptionGiftOrder,
  ExecuteSubscriptionGiftOrder,
  FetchSubscriptionGifts,
  ClaimSubscriptionGiftActionSuccess,
  CreateSubscriptionGiftPaypalOrderParams,
  CreateSubscriptionGiftPaypalOrder,
  ExecuteSubscriptionGifPaypalOrder,
} from './types';

// Bindings.
const createSubscriptionGiftOrderSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionGiftsActionTypes.CREATE_SUBSCRIPTION_GIFT_ORDER_SUCCESS, data);

const createSubscriptionGiftOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(
    SubscriptionGiftsActionTypes.CREATE_SUBSCRIPTION_GIFT_ORDER_ERROR,
    errorData,
    errorMapping,
  );

const executeSubscriptionGiftOrderSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionGiftsActionTypes.EXECUTE_SUBSCRIPTION_GIFT_ORDER_SUCCESS, data);

const executeSubscriptionGiftOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(
    SubscriptionGiftsActionTypes.EXECUTE_SUBSCRIPTION_GIFT_ORDER_ERROR,
    errorData,
    errorMapping,
  );

const createSubscriptionGiftPaypalOrderSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionGiftsActionTypes.CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_REQUEST, data);

const createSubscriptionGiftPaypalOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(
    SubscriptionGiftsActionTypes.CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_ERROR,
    errorData,
    errorMapping,
  );

const executeSubscriptionGiftPaypalOrderSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionGiftsActionTypes.EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_SUCCESS, data);

const executeSubscriptionGiftPaypalOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(
    SubscriptionGiftsActionTypes.EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_ERROR,
    errorData,
    errorMapping,
  );

// ------ FETCHING
// Error fetching.
const subscriptionGiftsNotReceived = ({ errorData }): SubscriptionGiftsActionError =>
  actionCreator.createErrorAction(SubscriptionGiftsActionTypes.FETCH_ERROR, errorData);

// Fetching success.
const subscriptionGiftsReceived = ({ data }: { data: SubscriptionCommunityGift[] }): SubscriptionGiftsActionSuccess =>
  actionCreator.createAction(SubscriptionGiftsActionTypes.FETCH_SUCCESS, data);

// Retrieve all gifts
const fetchSubscriptionGifts: FetchSubscriptionGifts = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.PAGES.GET_SUBSCRIPTION_GIFTS,
    method: 'get',
    urlParameters: { slug },
    errorBinding: subscriptionGiftsNotReceived,
    loadingAction: SubscriptionGiftsActionTypes.FETCH_LOADING,
    successBinding: subscriptionGiftsReceived,
  });

// ------ CLAIM
// Error claim
const claimSubscriptionGiftNotReceived = ({ errorData }): ClaimSubscriptionGiftActionError =>
  actionCreator.createErrorAction(SubscriptionGiftsActionTypes.CLAIM_ERROR, errorData, errorMapping);

// Claim success
const claimSubscriptionGifsReceived = ({ data, additionalData }): ClaimSubscriptionGiftActionSuccess =>
  actionCreator.createAction(SubscriptionGiftsActionTypes.CLAIM_SUCCESS, {
    ...data,
    subscriptionGiftId: additionalData.subscriptionGiftId,
  });

// Perform claim.
const claimSubscriptionGift: ClaimSubscriptionGift = (subscriptionGiftId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTION_GIFTS.CLAIM,
    method: 'post',
    urlParameters: { subscriptionGiftId },
    errorBinding: claimSubscriptionGiftNotReceived,
    successBinding: claimSubscriptionGifsReceived,
    additionalData: { subscriptionGiftId },
  });

const createSubscriptionGiftOrder: CreateSubscriptionGiftOrder = ({
  slug,
  ...parameters
}: CreateSubscriptionGifOrderParams) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.GIFT_INTENT,
    errorBinding: createSubscriptionGiftOrderError,
    loadingAction: SubscriptionGiftsActionTypes.CREATE_SUBSCRIPTION_GIFT_ORDER_REQUEST,
    parameters,
    urlParameters: {
      slug,
    },
    method: 'post',
    successBinding: createSubscriptionGiftOrderSuccess,
  });

const executeSubscriptionGiftOrder: ExecuteSubscriptionGiftOrder = (
  slug: string,
  transactionId: string,
  giftType: GiftSubscriptionType,
) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.GIFT_CONFIRM,
    errorBinding: executeSubscriptionGiftOrderError,
    loadingAction: SubscriptionGiftsActionTypes.EXECUTE_SUBSCRIPTION_GIFT_ORDER_REQUEST,
    urlParameters: {
      transactionId,
      slug,
    },
    method: 'post',
    parameters: {
      type: giftType,
    },
    successBinding: executeSubscriptionGiftOrderSuccess,
  });

const createSubscriptionGiftPaypalOrder: CreateSubscriptionGiftPaypalOrder = ({
  slug,
  ...parameters
}: CreateSubscriptionGiftPaypalOrderParams) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.GIFT_PAYPAL,
    errorBinding: createSubscriptionGiftPaypalOrderError,
    loadingAction: SubscriptionGiftsActionTypes.CREATE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_REQUEST,
    parameters,
    urlParameters: {
      slug,
    },
    method: 'post',
    successBinding: createSubscriptionGiftPaypalOrderSuccess,
  });
};

const executeSubscriptionGiftPaypalOrder: ExecuteSubscriptionGifPaypalOrder = (
  paypalPayload: Record<string, unknown>,
) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.GIFT_PAYPAL_CONFIRM,
    errorBinding: executeSubscriptionGiftPaypalOrderError,
    loadingAction: SubscriptionGiftsActionTypes.EXECUTE_SUBSCRIPTION_GIFT_PAYPAL_ORDER_SUCCESS,
    method: 'post',
    parameters: paypalPayload,
    successBinding: executeSubscriptionGiftPaypalOrderSuccess,
  });

// Exports.
export const subscriptionGiftsActions = {
  fetchSubscriptionGifts,
  claimSubscriptionGift,
  createSubscriptionGiftOrder,
  executeSubscriptionGiftOrder,
  createSubscriptionGiftPaypalOrder,
  executeSubscriptionGiftPaypalOrder,
};
