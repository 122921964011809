import ClassNames from '@streamloots/classnames';
import { PageSubscriptionPlan } from 'model/indexTS';
import { useSubscriptionPacks } from 'scenes/public-user-page/components/subscription-packs/useSubscriptionPacks';
import { TranslateInterface } from 'utils/translation';
import { PageModulesChecker } from 'components/page-modules-checker';
import { PagesModules } from '@streamloots/streamloots-types';
import { PanelWithTitleOutside } from 'ui-library/panel';
import { useMemo } from 'react';
import { SubscriptionPerk } from './SubscriptionPerk';
import theme from './subscription-info.scss';

const classNames = ClassNames(theme);

export interface SubscriptionInfoProps extends TranslateInterface {
  isGift?: boolean;
  subscriptionPlan: PageSubscriptionPlan;
}

export const SubscriptionInfo = ({ subscriptionPlan, t }: SubscriptionInfoProps): JSX.Element => {
  const { enabledAlerts } = subscriptionPlan;
  const packInfo = useSubscriptionPacks();

  const firstAlert = useMemo(() => {
    if (!subscriptionPlan.enabledAlerts.length) {
      return undefined;
    }

    const firstAlertId = subscriptionPlan.enabledAlerts[0];
    return subscriptionPlan.alerts.find(alertInfo => alertInfo._id === firstAlertId);
  }, [subscriptionPlan]);

  const emotePerkImage = useMemo(() => {
    const { emotesEnabled, enabledEmotes } = subscriptionPlan;

    if (!emotesEnabled || !enabledEmotes?.length) {
      return undefined;
    }
    return 'https://cdn.streamloots.com/uploads/admin-resources/34eefd11-f93d-467a-9a08-4d8b45883c90.gif';
    // if (!emoteCollections?.length || !emoteCollections[0].emotes.length) {
    //   return 'https://cdn.streamloots.com/uploads/admin-resources/34eefd11-f93d-467a-9a08-4d8b45883c90.gif';
    // }

    // return emoteCollections[0].emotes[0].imageUrl;
  }, [subscriptionPlan]);

  return (
    <PanelWithTitleOutside
      className={classNames('subscription-info')}
      title={t('pageSubscription:checkout.subscriptionPerks')}
      contentClassName={classNames('subscription-perks')}
    >
      <PageModulesChecker modulesRequired={PagesModules.Cards}>
        <SubscriptionPerk
          text={t('pageSubscription:checkout.perks.anonymousCards')}
          imageUrl="https://cdn.streamloots.com/uploads/admin-resources/69242bc6-bf58-44ad-ac07-9fa06448e566.png"
        />
        <SubscriptionPerk text={t('pageSubscription:checkout.perks.highlightedUsername')} isUsername />

        {packInfo && (
          <SubscriptionPerk
            text={t('pageSubscription:checkout.perks.freePacks', {
              packs: t('common:freePacksCount', { count: packInfo.quantity }),
            })}
            imageUrl={packInfo.lootImageUrl}
          />
        )}
      </PageModulesChecker>
      <SubscriptionPerk
        text={t('pageSubscription:checkout.perks.subscriptionAlerts')}
        imageUrl="https://com-streamloots-static.s3.amazonaws.com/b355d1ef-d931-4c16-a48f-8bed0076401b/subscription-alerts/coin_1.png"
      />
      <SubscriptionPerk text={t('pageSubscription:checkout.perks.contentForSubs')} icon="diamond" />

      {firstAlert && (
        <SubscriptionPerk
          text={t('pageSubscription:checkout.perks.alertsIncluded', {
            count: enabledAlerts.length,
          })}
          imageUrl={firstAlert.imageUrl}
        />
      )}
      {emotePerkImage && (
        <SubscriptionPerk text={t('pageSubscription:checkout.perks.emotes')} imageUrl={emotePerkImage} />
      )}
    </PanelWithTitleOutside>
  );
};
