import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSetLootsActions } from './actions';
import { userSetLootsSelectors } from './selectors';
import { OpenLoot, UserSetLootsBySetId } from './types';

interface UseUserSetLootsActions {
  openLoot: OpenLoot;
}

export const useUserSetLootsActions = (): UseUserSetLootsActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      openLoot: params => dispatch(userSetLootsActions.openLoot(params)),
    }),
    [dispatch],
  );
};

interface UseUserSetLootsSelectors {
  totalUnopenedLootsCount: number;
  allCollectionLoots: UserSetLootsBySetId[];
}

export const useUserSetLootsSelectors = (): UseUserSetLootsSelectors => {
  const totalUnopenedLootsCount = useSelector(userSetLootsSelectors.totalUnopenedLootsCount);
  const allCollectionLoots = useSelector(userSetLootsSelectors.collectionLootsFromAllUserSets);

  return {
    totalUnopenedLootsCount,
    allCollectionLoots,
  };
};
