import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import ILLUSTRATIONS from 'constants/illustrations';
import { PageSubscriptionPlan } from 'model/indexTS';
import { TranslateInterface } from 'utils/translation';
import Button from 'ui-library/button';
import { PanelWithoutHeader } from 'ui-library/panel';
import theme from './last-step.scss';

const classNames = ClassNames(theme);

export interface LastStepProps extends TranslateInterface {
  slug: string;
  onClose: () => void;
}

export const LastStep = ({ slug, onClose, t }: LastStepProps): JSX.Element => {
  return (
    <PanelWithoutHeader className={classNames('last-step__container')}>
      <div className={classNames('last-step')}>
        <div className={classNames('last-step__area')}>
          <img className={classNames('last-step__img')} src={ILLUSTRATIONS.WELCOME_STREAMERS} alt="" />
        </div>
        <div className={classNames('last-step__area align-left')}>
          <p>{t('pageSubscription:checkout.lastStep.thanks', { slug })}</p>
          <Button className={classNames('last-step__button')} onClick={onClose} primary>
            {t('pageSubscription:checkout.lastStep.seeSubscription')}
          </Button>
        </div>
      </div>
    </PanelWithoutHeader>
  );
};
