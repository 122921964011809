import ClassNames from '@streamloots/classnames';
import { PageSubscriptionPlan, RenewalPeriod } from 'model/indexTS';
import { TranslateInterface } from 'utils/translation';
import { currencyManager } from 'services/culture';
import theme from './subscription-info.scss';

const classNames = ClassNames(theme);

export interface PaymentSummaryProps extends TranslateInterface {
  quantity: number;
  subscriptionPlan: PageSubscriptionPlan;
  renewalPeriod?: RenewalPeriod;
}

export const PaymentSummary = ({ quantity, subscriptionPlan, renewalPeriod, t }: PaymentSummaryProps): JSX.Element => {
  const unirPriceNumber = renewalPeriod
    ? renewalPeriod.totalPrepaidMonths * subscriptionPlan.price
    : subscriptionPlan.price;
  const unitPrice = currencyManager.getFormattedPrice(unirPriceNumber, subscriptionPlan.currency);
  const totalPrice = currencyManager.getFormattedPrice(
    quantity * (renewalPeriod?.price ?? subscriptionPlan.price),
    subscriptionPlan.currency,
  );
  const discount =
    renewalPeriod &&
    currencyManager.getFormattedPrice(renewalPeriod.price - unirPriceNumber, subscriptionPlan.currency);
  return (
    <div className={classNames('payment-summary')}>
      <div className={classNames('payment-summary__line')}>
        <span>{t('pageSubscription:checkout.secondStep.singlePrice')}</span>
        <span>{unitPrice}</span>
      </div>
      {renewalPeriod && (
        <div className={classNames('payment-summary__line payment-summary__line--discount')}>
          <span>{t('pageSubscription:checkout.secondStep.discount')}</span>
          <span>{discount}</span>
        </div>
      )}
      <div className={classNames('payment-summary__line payment-summary__line--total')}>
        <span>{t('pageSubscription:checkout.secondStep.totalAmount')}</span>
        <span>{totalPrice}</span>
      </div>
    </div>
  );
};
