import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './panel.scss';

const classNames = ClassNames(theme);

interface PanelWithTitleOutsideProps {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  title: React.ReactNode;
  titleClassName?: string;
}

export const PanelWithTitleOutside = ({
  children,
  className = '',
  contentClassName = '',
  title,
  titleClassName = '',
}: PanelWithTitleOutsideProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'panel-t-o': true,
        [className]: Boolean(className),
      })}
    >
      <h3
        className={classNames({
          'panel-t-o__title': true,
          [titleClassName]: Boolean(titleClassName),
        })}
      >
        {title}
      </h3>
      <div
        className={classNames({
          'panel-t-o__content': true,
          [contentClassName]: Boolean(contentClassName),
        })}
      >
        {children}
      </div>
    </div>
  );
};
