import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { normalizeResponse } from 'services/user-set-loots/normalizer';
import {
  SubscriptionActionSuccess,
  SubscriptionActionError,
  SubscriptionPlayAlertSucess,
  ClaimSubscriptionPackActionError,
  ClaimSubscriptionPackActionSuccess,
} from './types';
import { SubscriptionsActionTypes } from './actionTypes';
import { errorMapping } from './errorMapping';

// Bindings.
const subscriptionsReceived = ({ data }): SubscriptionActionSuccess =>
  actionCreator.createAction(SubscriptionsActionTypes.RECEIVED, data);

const subscriptionsNotReceived = ({ errorData }): SubscriptionActionError =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.ERROR, errorData);

const alertPlayed = (): SubscriptionPlayAlertSucess =>
  actionCreator.createAction(SubscriptionsActionTypes.ALERT_PLAYED);

const alertPlayedError = ({ errorData }) =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.ALERT_PLAYED, errorData);

const createSubscriptionsOrderSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionsActionTypes.CREATE_SUBSCRIPTION_ORDER_SUCCESS, data);

const createSubscriptionsOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.CREATE_SUBSCRIPTION_ORDER_ERROR, errorData, errorMapping);

const executeSubscriptionOrderSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionsActionTypes.EXECUTE_SUBSCRIPTION_ORDER_SUCCESS, data);

const executeSubscriptionOrderError = ({ errorData }) =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.EXECUTE_SUBSCRIPTION_ORDER_ERROR, errorData, errorMapping);

const cancelSubscriptionSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_SUCCESS, data);

const cancelSubscriptionError = ({ errorData }) =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_ERROR, errorData, errorMapping);

const subscriptionPlanPaypalReceived = ({ data }) =>
  actionCreator.createAction(SubscriptionsActionTypes.FETCH_PAYPAL_SUCCESS, data);

const subscriptionPlanPaypalNotReceived = ({ errorData }) =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.FETCH_PAYPAL_ERROR, errorData, errorMapping);

const approveSubscriptionPaypalError = ({ errorData }) =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.APPROVE_PAYPAL_ERROR, errorData, errorMapping);

const approveSubscriptionPaypalSuccess = ({ data }) =>
  actionCreator.createAction(SubscriptionsActionTypes.APPROVE_PAYPAL_SUCCESS, data);

// Actions.
const fetchSubscriptions = (slug: string) =>
  actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.SUBSCRIPTIONS.GET,
    urlParameters: { slug },
    successBinding: subscriptionsReceived,
    errorBinding: subscriptionsNotReceived,
    loadingAction: SubscriptionsActionTypes.FETCHING,
  });

const getSubscriptionPlanPaypal = (slug: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.GET_PAYPAL,
    method: 'get',
    urlParameters: { slug },
    successBinding: subscriptionPlanPaypalReceived,
    errorBinding: subscriptionPlanPaypalNotReceived,
    loadingAction: SubscriptionsActionTypes.FETCH_PAYPAL_LOADING,
  });

const approveSubscriptionPlanPaypal = (slug: string, data: Record<string, unknown>) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.APPROVE_PAYPAL,
    method: 'post',
    urlParameters: { slug },
    parameters: { ...data },
    successBinding: approveSubscriptionPaypalSuccess,
    errorBinding: approveSubscriptionPaypalError,
    loadingAction: SubscriptionsActionTypes.APPROVE_PAYPAL_LOADING,
  });

const playAlert = (slug: string, _id: string) =>
  actionCreator.createAsyncAction({
    method: 'post',
    endpoint: endpoints.SUBSCRIPTIONS.PLAY,
    urlParameters: { slug },
    parameters: { _id },
    successBinding: alertPlayed,
    errorBinding: alertPlayedError,
  });

const createSubscriptionOrder = (slug: string, stripeToken: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.POST,
    errorBinding: createSubscriptionsOrderError,
    loadingAction: SubscriptionsActionTypes.CREATE_SUBCRIPTION_ORDER_REQUEST,
    parameters: {
      stripeToken,
    },
    urlParameters: {
      slug,
    },
    method: 'post',
    successBinding: createSubscriptionsOrderSuccess,
  });

const executeSubscriptionOrder = (slug: string, paymentId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.PAYMENT_CONFIRM,
    errorBinding: executeSubscriptionOrderError,
    loadingAction: SubscriptionsActionTypes.EXECUTE_SUBSCRIPTION_ORDER_REQUEST,
    urlParameters: {
      paymentId,
      slug,
    },
    method: 'post',
    successBinding: executeSubscriptionOrderSuccess,
  });

const cancelSubscription = (subscriptionId: string) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.SUBSCRIPTIONS.CANCEL,
    errorBinding: cancelSubscriptionError,
    loadingAction: SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_REQUEST,
    urlParameters: {
      subscriptionId,
    },
    method: 'delete',
    successBinding: cancelSubscriptionSuccess,
  });

const claimSubscriptionPackSuccess = ({ data }): ClaimSubscriptionPackActionSuccess =>
  actionCreator.createAction(SubscriptionsActionTypes.CLAIM_PACK_SUCCESS, normalizeResponse(data));

const claimSubscriptionPackError = ({ errorData }): ClaimSubscriptionPackActionError =>
  actionCreator.createErrorAction(SubscriptionsActionTypes.CLAIM_PACK_ERROR, errorData, errorMapping);

const claimSubscriptionPack = (slug: string, setId: string, amount: number) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.PAGE_SUBSCRIPTIONS.CLAIM_PACK,
    errorBinding: claimSubscriptionPackError,
    loadingAction: SubscriptionsActionTypes.FETCHING,
    urlParameters: { slug, setId },
    parameters: {
      amount,
    },
    method: 'post',
    successBinding: claimSubscriptionPackSuccess,
  });
};

export const subscriptionsActions = {
  claimSubscriptionPack,
  fetchSubscriptions,
  playAlert,
  createSubscriptionOrder,
  executeSubscriptionOrder,
  cancelSubscription,
  getSubscriptionPlanPaypal,
  approveSubscriptionPlanPaypal,
};
