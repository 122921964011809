import { createSelector } from 'reselect';
import {
  GetSubscriptionPlanSelectorOutput,
  PageSubscriptionPlansReduxState,
  PageSubscriptionPlansState,
} from './types';

const pageSubscriptionPlans = (state: PageSubscriptionPlansReduxState): PageSubscriptionPlansState =>
  state.pageSubscriptionPlans ?? {};

const getSubscriptionPlan = createSelector<
  PageSubscriptionPlansReduxState,
  PageSubscriptionPlansState,
  GetSubscriptionPlanSelectorOutput
>(
  pageSubscriptionPlans,
  (pageSubscriptionPlansState: PageSubscriptionPlansState): GetSubscriptionPlanSelectorOutput => {
    return pageSubscriptionPlansState?.subscriptionPlan ?? null;
  },
);

const isFetchingSubscriptionPlan = (state: PageSubscriptionPlansReduxState): boolean =>
  state.pageSubscriptionPlans?.isLoading ?? true;

export const pageSubscriptionPlansSelectors = {
  pageSubscriptionPlans,
  getSubscriptionPlan,
  isFetchingSubscriptionPlan,
};
