import { PaymentMethods } from '@streamloots/streamloots-types';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons/Icons';
import { RadioButtonIcon } from 'ui-library/radio-button-card/RadioButtonIcon';
import { TranslateInterface } from 'utils/translation';
import theme from './payment-methods.scss';

export interface PaymentMethodsRadioProps extends TranslateInterface {
  processor: PaymentMethods;
  selected?: boolean;
  onChange: (selectedMethod: PaymentMethods) => void;
}

const classNames = ClassNames(theme);

export const PaymentMethodRadio = ({
  processor,
  selected = false,
  onChange,
  t,
}: PaymentMethodsRadioProps): JSX.Element => {
  const label = t(`checkout:providerLabels.${processor.toLowerCase()}`);
  const icon = processor !== PaymentMethods.STRIPE ? processor.toLowerCase() : 'card';

  const handleOnChange = () => onChange(processor);

  return (
    <div className={classNames({ 'payment-methods__option': true, 'payment-methods__option--selected': selected })}>
      <label htmlFor={label}>
        <input id={label} type="radio" checked={selected} value={processor} onChange={handleOnChange} />
        <span className="visuallyhidden">{processor}</span>
      </label>
      <div className={classNames('payment-methods__option__check')}>
        <RadioButtonIcon selectable checked={selected} />
      </div>
      <div className={classNames('payment-methods__option__content')}>
        <div
          className={classNames({
            'payment-methods__option__content__icon': true,
            'payment-methods__option__content__icon--selected': selected,
          })}
        >
          <Icon id={selected ? `${icon}_selected` : `${icon}_default`} />
        </div>
        <div
          className={classNames({
            'payment-methods__option__content__name': true,
            'payment-methods__option__content__name--selected': selected,
          })}
        >
          {label}
        </div>
      </div>
    </div>
  );
};
