import ClassNames from '@streamloots/classnames';
import { Trans } from 'utils/translation';
import { PanelWithoutHeader } from 'ui-library/panel';
import AvatarImage from 'components/avatar-image';
import theme from './subscription-info.scss';

const classNames = ClassNames(theme);

export interface PaymentStepHeaderProps {
  avatarImageUrl: string;
  slug: string;
}

export const PaymentStepHeader = ({ avatarImageUrl, slug }: PaymentStepHeaderProps): JSX.Element => {
  return (
    <PanelWithoutHeader className="my-6" color="black-mid">
      <div className="flex items-center gap-3 justify-center">
        <AvatarImage src={avatarImageUrl} bordered rounded size="md" />
        <span className={classNames('payment-step__title')}>
          <Trans i18nKey="pageSubscription:checkout.title">
            {'You are subscribing to '}
            <strong>{slug}</strong>
          </Trans>
        </span>
      </div>
    </PanelWithoutHeader>
  );
};
