import { SubscriptionStatus } from 'domain/subscription-status';
import { createSelector } from 'reselect';
import { GetSubscriptionOutput, SubscriptionsReduxState, SubscriptionsState } from './types';

const getSubscriptionsState = (state: SubscriptionsReduxState): SubscriptionsState => state.subscriptions ?? {};

const getSubscription = createSelector<SubscriptionsReduxState, SubscriptionsState, GetSubscriptionOutput>(
  [getSubscriptionsState],
  (state: SubscriptionsState): GetSubscriptionOutput => {
    return state?.subscription ?? null;
  },
);

const getRemainingAlerts = createSelector<SubscriptionsReduxState, SubscriptionsState, number>(
  [getSubscriptionsState],
  (state: SubscriptionsState): number => {
    return state?.subscription?.remainingAlerts || 0;
  },
);

const isSubscribed = createSelector<SubscriptionsReduxState, SubscriptionsState, boolean>(
  [getSubscriptionsState],
  (state: SubscriptionsState): boolean => {
    return SubscriptionStatus.isActive(state?.subscription);
  },
);

const getPaypalPlanId = createSelector<SubscriptionsReduxState, SubscriptionsState, string | undefined>(
  [getSubscriptionsState],
  (state: SubscriptionsState): string | undefined => {
    return state.paypalPlanId;
  },
);

const getPaypalClientId = createSelector<SubscriptionsReduxState, SubscriptionsState, string | undefined>(
  [getSubscriptionsState],
  (state: SubscriptionsState): string | undefined => {
    return state.paypalClientId;
  },
);

export const subscriptionSelectors = {
  getSubscription,
  getRemainingAlerts,
  isSubscribed,
  getPaypalPlanId,
  getPaypalClientId,
};
