import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  FetchPageSubcriptionPlans,
  UpdatePageSubcriptionPlans,
  PageSubcriptionPlanFormValues,
  PageSubscriptionPlansState,
} from './types';
import { pageSubscriptionPlansSelectors } from './selectors';
import { pageSubscriptionPlansActions } from './actions';

interface UsePageSubscriptionPlansActions {
  updatePageSubscriptionPlans: UpdatePageSubcriptionPlans;
  fetchPageSubscriptionPlans: FetchPageSubcriptionPlans;
}
type UsePageSubscriptionPlansSelectors = PageSubscriptionPlansState;

export const usePageSubscriptionPlansActions = (): UsePageSubscriptionPlansActions => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchPageSubscriptionPlans: (slug: string) =>
        dispatch(pageSubscriptionPlansActions.fetchPageSubscriptionPlans(slug)),
      updatePageSubscriptionPlans: (slug: string, values: PageSubcriptionPlanFormValues) =>
        dispatch(pageSubscriptionPlansActions.updatePageSubscriptionPlans(slug, values)),
    }),
    [dispatch],
  );
};

export const usePageSubscriptionPlansSelectors = (): UsePageSubscriptionPlansSelectors => {
  return useSelector(pageSubscriptionPlansSelectors.pageSubscriptionPlans);
};
