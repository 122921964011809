import type { ReactNode } from 'react';
import ClassNames from '@streamloots/classnames';
import { ButtonIcon } from 'ui-library/button';
import Icon from 'ui-library/icons';
import theme from './tip.scss';

const classNames = ClassNames(theme);

export interface TipProps {
  className?: string;
  children: ReactNode;
  overPageBg?: boolean;
  onClose?: () => void;
}

export const Tip = ({ className = '', overPageBg = false, children, onClose }: TipProps): JSX.Element => (
  <div
    className={classNames({
      'tip': true,
      [className]: Boolean(className),
      'tip--over-bg': overPageBg,
    })}
  >
    <Icon id="idea" size="big" />
    <p>{children}</p>
    {onClose && <ButtonIcon className={classNames('tip__close')} onClick={onClose} asLink icon="close" />}
  </div>
);
