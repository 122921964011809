import * as React from 'react';
import { connect } from 'react-redux';
import { userSelectors } from 'services/user';

interface IsOnExperimentOwnProps {
  experimentName: string;
  segmentToCheck: string;
  renderExperiment: () => JSX.Element | null;
  renderDefault?: () => JSX.Element | null;
  customCondition?: () => boolean;
}

interface IsOnExperimentMapStateToProps {
  experimentSegment: string;
}

interface IsOnExperimentProps extends IsOnExperimentMapStateToProps, IsOnExperimentOwnProps {}

const IsOnExperimentInternal = ({
  segmentToCheck,
  renderExperiment,
  renderDefault,
  experimentSegment,
  customCondition,
}: IsOnExperimentProps): JSX.Element | null => {
  if ((typeof customCondition === 'function' && customCondition()) || experimentSegment === segmentToCheck) {
    return renderExperiment();
  }
  return typeof renderDefault === 'function' ? renderDefault() : null;
};

const mapStateToProps = (state, props: IsOnExperimentOwnProps) => ({
  experimentSegment: userSelectors.userSegmentFromExperiment(state, props.experimentName),
});

export const IsOnExperiment = connect<IsOnExperimentMapStateToProps, null, IsOnExperimentOwnProps>(mapStateToProps)(
  IsOnExperimentInternal,
);
