import { useEffect, useState } from 'react';
import ClassNames from '@streamloots/classnames';
import { SubscriptionStatus } from '@streamloots/streamloots-types';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { FixMe } from 'types/indexTS';
import { I18nType, TranslateInterface, withTranslation } from 'utils/translation';
import { pageSelectors, PageState } from 'services/page';
import {
  FetchSubscriptions,
  GetSubscriptionOutput,
  subscriptionsActions,
  subscriptionSelectors,
} from 'services/subscriptions';
import {
  FetchPageSubcriptionPlans,
  GetSubscriptionPlanSelectorOutput,
  pageSubscriptionPlansActions,
  pageSubscriptionPlansSelectors,
} from 'services/page-subscription-plans';
import { authSelectors } from 'services/auth';
import { CleanDialogContent } from 'ui-library/dialog';
import { Alert } from 'ui-library/alert';
import { PaymentStep } from './components/payment-step';
import { LastStep } from './components/last-step';
import theme from './subscription-checkout.scss';

const classNames = ClassNames(theme);

interface SubscriptionCheckoutDialogProps {
  onClose: () => void;
}

interface MapStateToProps {
  isUserAuthenticated: boolean;
  slug: string;
  page: PageState;
  isOwner: boolean;
  subscriptionPlan: GetSubscriptionPlanSelectorOutput;
  subscription: GetSubscriptionOutput;
}

interface MapDispatchToProps {
  fetchPageSubscriptionPlans: FetchPageSubcriptionPlans;
  fetchSubscription: FetchSubscriptions;
}

interface ExtendedProps
  extends SubscriptionCheckoutDialogProps,
    MapStateToProps,
    MapDispatchToProps,
    TranslateInterface {
  i18n: I18nType;
}

const SubscriptionCheckoutDialogBase = ({
  isOwner,
  slug,
  page,
  subscriptionPlan,
  subscription,
  i18n,
  isUserAuthenticated,
  onClose,
  fetchPageSubscriptionPlans,
  fetchSubscription,
  t,
}: ExtendedProps): JSX.Element | null => {
  const [isSubscriptionPurchased, setIsSubscriptionPurchased] = useState(false);
  const isSubscribed = subscription?.status === SubscriptionStatus.Active;

  useEffect(() => {
    if (!slug) {
      return;
    }

    fetchPageSubscriptionPlans(slug);
  }, [fetchPageSubscriptionPlans, slug]);

  if (!subscriptionPlan) {
    return null;
  }

  const handleSubscriptionComplete = () => {
    fetchSubscription(slug);
    setIsSubscriptionPurchased(true);
  };

  if (isSubscribed && !isSubscriptionPurchased) {
    return (
      <CleanDialogContent onClose={onClose}>
        <Alert type={Alert.ALERT_TYPES.ERROR}>{t('pageSubscription:checkout.firstStep.alreadySubscribed')}</Alert>;
      </CleanDialogContent>
    );
  }

  return (
    <CleanDialogContent
      className={classNames({
        'align-center': true,
        'p-5': true,
        'subscription-checkout__last-step': isSubscriptionPurchased,
      })}
      onClose={onClose}
    >
      {!isSubscriptionPurchased ? (
        <PaymentStep
          isUserAuthenticated={isUserAuthenticated}
          subscriptionPlan={subscriptionPlan}
          isOwner={isOwner}
          slug={slug}
          avatarImageUrl={page?.owner?.profile.avatarImageUrl || ''}
          t={t}
          i18n={i18n}
          nextStep={handleSubscriptionComplete}
          onCloseLogin={onClose}
        />
      ) : (
        <LastStep onClose={onClose} slug={slug} t={t} />
      )}
    </CleanDialogContent>
  );
};

const mapStateToProps = (state: FixMe): MapStateToProps => {
  return {
    isUserAuthenticated: authSelectors.isUserAuthenticated(state),
    slug: pageSelectors.slug(state),
    page: pageSelectors.page(state),
    isOwner: pageSelectors.isOwner(state),
    subscriptionPlan: pageSubscriptionPlansSelectors.getSubscriptionPlan(state),
    subscription: subscriptionSelectors.getSubscription(state),
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  fetchPageSubscriptionPlans: pageSubscriptionPlansActions.fetchPageSubscriptionPlans,
  fetchSubscription: subscriptionsActions.fetchSubscriptions,
};

export const SubscriptionCheckoutDialog = compose<React.FunctionComponent<SubscriptionCheckoutDialogProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('pageSubscription'),
)(SubscriptionCheckoutDialogBase);
