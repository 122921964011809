import ClassNames from '@streamloots/classnames';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { subscriptionsActions, subscriptionSelectors } from 'services/subscriptions';
import { ApproveSubscriptionPlanPaypal, GetSubscriptionPlanPaypal } from 'services/subscriptions/types';
import { cultureSelectors } from 'services/culture';
import Skeleton from 'ui-library/skeleton';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Languages } from '@streamloots/streamloots-types';
import { useReactAlert } from 'components/react-alert-provider';
import theme from './paypal.scss';

interface PayPalProps {
  slug: string;
  nextStep: () => void;
}

interface MapStateToProps {
  paypalPlanId?: string;
  paypalClientId?: string;
  language: Languages;
}

interface MapDispatchToProps {
  getSubscriptionPlanPaypal: GetSubscriptionPlanPaypal;
  approveSubscriptionPlanPaypal: ApproveSubscriptionPlanPaypal;
}

interface ExtendedProps extends PayPalProps, MapStateToProps, MapDispatchToProps {}

const classNames = ClassNames(theme);

const PaypalBase = ({
  paypalPlanId,
  paypalClientId,
  slug,
  getSubscriptionPlanPaypal,
  language,
  approveSubscriptionPlanPaypal,
  nextStep,
}: ExtendedProps): JSX.Element => {
  const { showError } = useReactAlert();

  useEffect(() => {
    getSubscriptionPlanPaypal(slug);
  }, [getSubscriptionPlanPaypal, slug]);

  if (!paypalPlanId || !paypalClientId) {
    return <Skeleton height={40} />;
  }

  return (
    <div className={classNames('paypal')}>
      <PayPalScriptProvider
        options={{
          'client-id': paypalClientId,
          'vault': true,
          'locale': language === Languages.Spanish ? 'es_ES' : 'en_US',
          'disable-funding': 'credit',
        }}
      >
        <PayPalButtons
          fundingSource="paypal"
          style={{
            color: 'gold',
            label: 'pay',
            shape: 'rect',
            height: 40,
            tagline: false,
          }}
          createSubscription={(_, actions) => {
            return actions.subscription.create({
              plan_id: paypalPlanId,
              application_context: {
                shipping_preference: 'NO_SHIPPING',
              },
            });
          }}
          onApprove={async data => {
            const { error } = await approveSubscriptionPlanPaypal(slug, data);
            if (error?.message) {
              showError(error.message);
              return;
            }
            nextStep();
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => ({
  language: cultureSelectors.language(state),
  paypalPlanId: subscriptionSelectors.getPaypalPlanId(state),
  paypalClientId: subscriptionSelectors.getPaypalClientId(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  getSubscriptionPlanPaypal: subscriptionsActions.getSubscriptionPlanPaypal,
  approveSubscriptionPlanPaypal: subscriptionsActions.approveSubscriptionPlanPaypal,
};

export const Paypal = connect(mapStateToProps, mapDispatchToProps)(PaypalBase);
