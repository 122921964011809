import { useEffect, useState } from 'react';
import { PageSubscriptionPlan } from 'model/indexTS';
import { I18nType, TranslateInterface } from 'utils/translation';
import { usePageSelectors } from 'services/page';
import { CleanDialogContent } from 'ui-library/dialog';
import trackEventManager from 'utils/event-manager';
import { AuthDialog } from 'scenes/auth';
import { getUrlWithParams, pages } from 'services/routing';
import { SubscriptionCheckoutFromLoginLocations } from 'scenes/public-user-page/components/open-subscription-checkout-from-login';
import { AuthProviders, PaymentMethods } from '@streamloots/streamloots-types';
import { Alert } from 'ui-library/alert';
import { PaymentStepHeader, SubscriptionInfo } from './subscription-info';
import { Stripe } from './stripe';
import { PaymentSummary } from './subscription-info/PaymentSummary';
import { PaymentOptions } from './options/PaymentOptions';
import { Paypal } from './paypal';
import { SubscriptionVsTwitchMessage } from './subscription-info/SubscriptionVsTwitchMessage';

export interface PaymentStepProps extends TranslateInterface {
  isUserAuthenticated: boolean;
  avatarImageUrl: string;
  slug: string;
  subscriptionPlan: PageSubscriptionPlan;
  i18n: I18nType;
  isOwner: boolean;
  nextStep: () => void;
  onCloseLogin: () => void;
}

export const PaymentStep = ({
  isUserAuthenticated,
  avatarImageUrl,
  slug,
  subscriptionPlan,
  isOwner,
  i18n,
  t,
  nextStep,
  onCloseLogin,
}: PaymentStepProps): JSX.Element => {
  const {
    page: { owner },
  } = usePageSelectors();
  const isTwitchProvider = owner?.primaryAuthProvider === AuthProviders.TWITCH;
  const [processor, setProcessor] = useState(PaymentMethods.STRIPE);

  useEffect(() => {
    if (!isUserAuthenticated) {
      return;
    }

    trackEventManager.subscriptionPurchaseStep({
      step: 'payment',
    });
  }, [isUserAuthenticated]);

  if (!isUserAuthenticated) {
    return (
      <AuthDialog
        onClose={onCloseLogin}
        componentKey={SubscriptionCheckoutFromLoginLocations.Checkout}
        redirectTo={`${getUrlWithParams(pages.USER_PAGE_SUBSCRIPTION, { slug })}?startLocation=${
          SubscriptionCheckoutFromLoginLocations.Checkout
        }`}
        location="subscription checkout"
      />
    );
  }

  const handleOnChangeMethod = (method: PaymentMethods): void => {
    setProcessor(method);
  };

  return (
    <CleanDialogContent>
      <PaymentStepHeader avatarImageUrl={avatarImageUrl} slug={slug} />
      <SubscriptionInfo subscriptionPlan={subscriptionPlan} t={t} />
      {isTwitchProvider && <SubscriptionVsTwitchMessage />}
      <PaymentSummary quantity={1} subscriptionPlan={subscriptionPlan} t={t} />
      {isOwner ? (
        <Alert type={Alert.ALERT_TYPES.ERROR}>{t('pageSubscription:checkout.firstStep.isOwner')}</Alert>
      ) : (
        <>
          <PaymentOptions t={t} method={processor} onChange={handleOnChangeMethod} />
          {processor === PaymentMethods.PAYPAL ? (
            <Paypal slug={slug} nextStep={nextStep} />
          ) : (
            <Stripe t={t} i18n={i18n} slug={slug} nextStep={nextStep} purchaseAsGift={false} />
          )}
        </>
      )}
    </CleanDialogContent>
  );
};
