export const errorMapping = {
  CREATE_A_HOLD_FIRST: 'pageSubscription:checkout.errors.createHoldFirst',
  SUBSCRIPTIONS_NOT_ENABLED: 'pageSubscription:checkout.errors.subscriptionsNotEnabled',
  ALREADY_SUBSCRIBED: 'pageSubscription:checkout.errors.alreadySubscribed',
  SELF_SUBSCRIPTION: 'pageSubscription:checkout.errors.selfSubscription',
  PROVIDER_ERROR: 'pageSubscription:checkout.errors.providerError',
  SUBSCRIPTION_PACKS_OPENINGS_LIMIT_REACHED: 'pageSubscription:packs.errors.openingLimitsReached',
  SET_NOT_GIFTABLE: 'pageSubscription:packs.errors.setNotGiftable',
  SUBSCRIPTION_PACKS_NOT_OPENED: 'pageSubscription:packs.errors.genericError',
  ACTIVE_SUBSCRIPTION_NOT_EXISTS: 'pageSubscription:packs.errors.subscriptionExpired',
  SUBSCRIPTION_PACKS_NOT_ENABLED: 'pageSubscription:packs.errors.subscriptionPacksNotEnabled',
  SUBSCRIPTION_PACKS_NOT_ENABLED_FOR_SET: 'pageSubscription:packs.errors.subscriptionPacksNotEnabledForSet',
};
