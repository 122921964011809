import { useMemo } from 'react';
import { useUserSetsSelectors } from 'services/user-sets';
import { useUserSetLootsSelectors } from 'services/user-set-loots';
import { usePageSubscriptionPlansSelectors } from 'services/page-subscription-plans';

export interface UseSubscriptionPacks {
  lootImageUrl: string;
  setName: string;
  setId: string;
  quantity: number;
}

export const useSubscriptionPacks = (): UseSubscriptionPacks | null => {
  const { subscriptionPlan } = usePageSubscriptionPlansSelectors();
  const { userSets } = useUserSetsSelectors();
  const { allCollectionLoots } = useUserSetLootsSelectors();

  const freePackInfo = useMemo(() => {
    if (!subscriptionPlan?.packsEnabled) {
      return null;
    }

    if (!userSets) {
      return null;
    }

    const { enabledPacks } = subscriptionPlan;
    if (!enabledPacks?.length) {
      return null;
    }

    const enabledPack = enabledPacks[0];
    const userSet = userSets.find(set => set._id === enabledPack.setId);

    if (!userSet) {
      return null;
    }

    return {
      lootImageUrl: allCollectionLoots.find(loot => loot.set._id === enabledPack.setId)?.lootImageUrl || '',
      setName: userSet.name,
      ...enabledPack,
    };
  }, [subscriptionPlan, userSets, allCollectionLoots]);

  return freePackInfo;
};
