import { SubscriptionStatus as SubscriptionStatusEnum } from '@streamloots/streamloots-types';
import { Subscription, Viewer } from 'model/indexTS';

export class SubscriptionStatus {
  static isActiveFromViewer(viewer: Viewer | null | undefined): boolean {
    if (!viewer) {
      return false;
    }

    const { subscriptionStatus, expirationDate, renovationDate } = viewer;
    return SubscriptionStatus.isActive({
      status: subscriptionStatus,
      expirationDate: expirationDate || renovationDate,
    });
  }

  static isActive(
    subscription: Partial<Pick<Subscription, 'status' | 'expirationDate' | 'renovationDate'>> | null | undefined,
  ): boolean {
    if (!subscription) {
      return false;
    }

    const { status } = subscription;
    const expirationDate = subscription.expirationDate || subscription.renovationDate;

    if (!status || !expirationDate) {
      return false;
    }

    const renovationTime = new Date(expirationDate).getTime();

    return (
      status === SubscriptionStatusEnum.Active ||
      (status === SubscriptionStatusEnum.Cancelled && renovationTime > Date.now())
    );
  }
}
