import { IsOnExperiment } from 'components/experiments';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageSelectors } from 'services/page';
import { PanelWithoutHeader } from 'ui-library/panel';
import { Tip } from 'ui-library/tip';
import trackEventManager from 'utils/event-manager';

export const SubscriptionVsTwitchMessage = (): JSX.Element => {
  const {
    page: { slug },
  } = usePageSelectors();
  const { i18n } = useTranslation();
  useEffect(() => {
    trackEventManager.trackEvent('section_viewed', { location: 'subscription vs twitch' });
  }, []);

  return (
    <IsOnExperiment
      experimentName="AB-SubsVsTwitchMessage-June24"
      renderExperiment={() => {
        if (i18n.language === 'es') {
          return (
            <Tip overPageBg>
              <p className="mb-0 color-brand">
                Con Streamloots, <strong className="capitalize">{slug}</strong> gana el 80% de la suscripción, mientras
                que con las de Twitch recibe menos del 50%.
              </p>
            </Tip>
          );
        }
        return (
          <Tip overPageBg>
            <p className="mb-0 color-brand">
              With Streamloots, <strong className="capitalize">{slug}</strong> get 80% of the subscription price, while
              with Twitch subscriptions <span className="capitalize">{slug}</span> will get less than 50%.
            </p>
          </Tip>
        );
      }}
      segmentToCheck="B"
    />
  );
};
