import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons/Icons';
import theme from './subscription-info.scss';

const classNames = ClassNames(theme);

export interface SubscriptionPerkProps {
  text: string;
  icon?: string;
  imageUrl?: string;
  isUsername?: boolean;
}

export const SubscriptionPerk = ({ text, icon, isUsername, imageUrl }: SubscriptionPerkProps): JSX.Element => {
  return (
    <div className={classNames('subscription-perk')}>
      <div className={classNames('subscription-perk__media')}>
        {imageUrl && <img src={imageUrl} alt="" />}
        {icon && <Icon id={icon} className={classNames('subscription-perk__icon')} />}
        {isUsername && <span className={classNames('subscription-perk__username')}>username</span>}
      </div>
      <p
        className="mb-0 mt-5"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </div>
  );
};
