import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import { TranslateInterface } from 'utils/translation';
import { paymentSelectors, paymentActions } from 'services/payment';
import { CreateSubscriptionOrder, ExecuteSubscriptionOrder, subscriptionsActions } from 'services/subscriptions';
import {
  CreateSubscriptionGiftOrder,
  ExecuteSubscriptionGiftOrder,
  SubscriptionGiftOrderFormValues,
  subscriptionGiftsActions,
} from 'services/page-subscription-gifts';
import Spinner from 'ui-library/loading-spinner';
import Button, { ButtonIcon } from 'ui-library/button';
import SavedCards from 'components/stripe-form/components/saved-cards';
import { useReactAlert } from 'components/react-alert-provider';
import { StripeForm } from 'components/stripe-form';
import { StripeFormAdditionalValues } from 'components/stripe-form/types';
import { StripePaymentMethod } from 'model/paymentsTS';
import { StripeType } from './types';
import theme from './stripe.scss';

const classNames = ClassNames(theme);

type StripeFormWrapperGiftProps = {
  purchaseAsGift: true;
  giftPayload: SubscriptionGiftOrderFormValues;
};

type StripeFormWrapperPurchaseProps = {
  purchaseAsGift: false;
};

type StripeFormWrapperFormValueProps = StripeFormWrapperGiftProps | StripeFormWrapperPurchaseProps;

type StripeFormWrapperProps = StripeFormWrapperFormValueProps &
  TranslateInterface & {
    slug: string;
    nextStep: () => void;
  };

interface MapStateToProps {
  isLoadingPaymentMethods?: boolean;
  savedCards?: StripePaymentMethod[];
}

interface MapDispatchToProps {
  createSubscriptionOrder: CreateSubscriptionOrder;
  executeSubscriptionOrder: ExecuteSubscriptionOrder;
  createSubscriptionGiftOrder: CreateSubscriptionGiftOrder;
  executeSubscriptionGiftOrder: ExecuteSubscriptionGiftOrder;
  fetchSavedStripePaymentMethods: () => void;
}

type ExtendedProps = StripeFormWrapperProps &
  MapStateToProps &
  MapDispatchToProps & {
    stripe: StripeType;
  };

const StripeFormWrapperBase = ({
  slug,
  stripe,
  savedCards,
  nextStep,
  createSubscriptionOrder,
  createSubscriptionGiftOrder,
  executeSubscriptionOrder,
  executeSubscriptionGiftOrder,
  fetchSavedStripePaymentMethods,
  t,
  ...rest
}: ExtendedProps): JSX.Element => {
  const showSavedCards = rest.purchaseAsGift && savedCards && savedCards.length > 0;
  const [isLoading, setIsLoading] = React.useState(false);
  const [allItemsLoaded, setAllItemsLoaded] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState('');
  const { showError } = useReactAlert();

  React.useEffect(() => {
    if (!rest.purchaseAsGift || savedCards) {
      return;
    }

    fetchSavedStripePaymentMethods();
  }, [fetchSavedStripePaymentMethods, rest.purchaseAsGift, savedCards]);

  React.useEffect(() => {
    if (!savedCards || savedCards.length === 0) {
      return;
    }
    setSelectedCard(savedCards[0]._id);
  }, [savedCards]);

  const handleAddNew = () => setSelectedCard('');

  const handleAllItemsLoaded = () => {
    setAllItemsLoaded(true);
  };

  const handleError = (errorMessage: string) => {
    setIsLoading(false);
    showError(errorMessage);
  };

  const handleAuthorizePayment = response => {
    const promise = rest.purchaseAsGift
      ? executeSubscriptionGiftOrder(slug, response.paymentIntent.id, rest.giftPayload.type)
      : executeSubscriptionOrder(slug, response.paymentIntent.id);

    promise.then(processPaymentResponse);
  };

  const authorizePayment = (paymentIntentClientSecret: string) => {
    const promise = rest.purchaseAsGift
      ? stripe.handleCardAction(paymentIntentClientSecret)
      : stripe.confirmCardPayment(paymentIntentClientSecret);

    promise.then(stripeResponse => {
      if (stripeResponse.error) {
        handleError(stripeResponse.error.message);
        return;
      }

      handleAuthorizePayment(stripeResponse);
    });
  };

  const processPaymentResponse = response => {
    if (response.error) {
      handleError(t(`pageSubscription:checkout.errors.${response.metadata?.businessCode ?? 'unknownError'}`));
      return;
    }

    const { payload } = response;
    const data = payload;

    const status = !rest.purchaseAsGift && data.paymentStatus === 'PENDING' ? 'USER_ACTION_NEEDED' : data.paymentStatus;

    switch (status) {
      case 'requires_confirmation':
      case 'requires_capture':
      case 'requires_action': // Remove Requires source action when upgrade stripe version
      case 'requires_source_action':
      case 'USER_ACTION_NEEDED':
      case 'PENDING': {
        authorizePayment(data.clientSecret ?? data.client_secret);
        return;
      }
      case 'processing': {
        handleError('pageSubscription:checkout.errors.paymentPending');
        return;
      }
      case 'canceled': {
        setIsLoading(false);
        handleError('pageSubscription:checkout.errors.paymentCanceled');
        return;
      }
      case 'succeeded':
      case 'PAID':
      case 'ACTIVE': {
        setIsLoading(false);
        nextStep();
        return;
      }
      case 'CANCELLED': {
        const cancelDate = new Date(data.expirationDate || data.renovationDate);
        const now = new Date();

        if (data.subscriptionType === 'GIFTED' && now.getTime() <= cancelDate.getTime()) {
          setIsLoading(false);
          nextStep();
          return;
        }
        handleError(t('pageSubscription:checkout.errors.paymentError'));
        return;
      }
      default:
        handleError(t(`pageSubscription:checkout.errors.${response.metadata?.businessCode ?? 'unknownError'}`));
    }
  };

  const handleCreatePayment = async (stripeToken: string) => {
    const stripePaymentResponse = rest.purchaseAsGift
      ? await createSubscriptionGiftOrder({ slug, stripeToken, ...rest.giftPayload })
      : await createSubscriptionOrder(slug, stripeToken);

    processPaymentResponse(stripePaymentResponse);
  };

  const handleSubmit = async (formFields: StripeFormAdditionalValues) => {
    const paymentData = {
      billing_details: { name: formFields.name },
    };
    setIsLoading(true);

    const stripeResponse = await stripe.createPaymentMethod('card', paymentData);

    const { error, paymentMethod } = stripeResponse;
    if (error) {
      handleError(error.message);
      return;
    }

    handleCreatePayment(paymentMethod.id);
  };

  const handleBuyWithSelectedCard = () => {
    setIsLoading(true);
    handleCreatePayment(selectedCard);
  };

  const isDisabled = isLoading || !allItemsLoaded;

  return (
    <div>
      {!allItemsLoaded && <Spinner overlay />}
      {showSavedCards && (
        <React.Fragment>
          <SavedCards
            t={t}
            onSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
            savedCards={savedCards}
            cardRowClassname={classNames('stripe__card-row')}
          />
          <div>
            <ButtonIcon
              small
              icon="plus"
              onClick={handleAddNew}
              className={classNames('stripe__other-card')}
              label={t('common:creditCardForm.addNewCard')}
            />
          </div>
        </React.Fragment>
      )}
      <StripeForm
        handleSubmit={handleSubmit}
        t={t}
        buttonLabel={t(
          `pageSubscription:checkout.secondStep.${rest.purchaseAsGift ? 'giftSubscription' : 'subscribeNow'}`,
        )}
        isHidden={showSavedCards && !!selectedCard}
        isLoading={false}
        onAllItemsLoaded={handleAllItemsLoaded}
        isDisabled={isDisabled}
      />
      {showSavedCards && selectedCard && (
        <Button
          data-testid="button-purchase-with-card"
          onClick={handleBuyWithSelectedCard}
          label={t(`pageSubscription:checkout.secondStep.${rest.purchaseAsGift ? 'giftSubscription' : 'subscribeNow'}`)}
          loading={isLoading}
          disabled={isDisabled}
          warning
          block
        />
      )}
    </div>
  );
};

const mapStateToProps = (state): MapStateToProps => {
  const savedStripePaymentMethodsStatus = paymentSelectors.savedStripePaymentMethodsStatus(state);
  return {
    isLoadingPaymentMethods: savedStripePaymentMethodsStatus.isLoading,
    savedCards: savedStripePaymentMethodsStatus.savedMethods,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  createSubscriptionOrder: subscriptionsActions.createSubscriptionOrder,
  executeSubscriptionOrder: subscriptionsActions.executeSubscriptionOrder,
  createSubscriptionGiftOrder: subscriptionGiftsActions.createSubscriptionGiftOrder,
  executeSubscriptionGiftOrder: subscriptionGiftsActions.executeSubscriptionGiftOrder,
  fetchSavedStripePaymentMethods: paymentActions.fetchSavedStripePaymentMethods,
};

export const StripeFormWrapper = compose<React.FunctionComponent<StripeFormWrapperProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  injectStripe,
)(StripeFormWrapperBase);
