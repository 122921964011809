import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { PageSubscriptionPlansActionTypes } from './actionTypes';
import {
  GET_PAGE_SUBSCRIPTION_PLANS_ERROR_ACTION,
  GET_PAGE_SUBSCRIPTION_PLANS_SUCCESS_ACTION,
  PageSubcriptionPlanFormValues,
  PUT_PAGE_SUBSCRIPTION_PLANS_ERROR_ACTION,
  PUT_PAGE_SUBSCRIPTION_PLANS_SUCCESS_ACTION,
} from './types';

const getPageSubscriptionPlansSuccess = ({ data }): GET_PAGE_SUBSCRIPTION_PLANS_SUCCESS_ACTION => {
  return actionCreator.createAction(PageSubscriptionPlansActionTypes.GET_PAGE_SUBSCRIPTION_PLANS_SUCCESS, data);
};

const getPageSubscriptionPlansError = ({ errorData }): GET_PAGE_SUBSCRIPTION_PLANS_ERROR_ACTION => {
  return actionCreator.createErrorAction(PageSubscriptionPlansActionTypes.GET_PAGE_SUBSCRIPTION_PLANS_ERROR, errorData);
};
const fetchPageSubscriptionPlans = (slug: string) =>
  actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.PAGE_SUBSCRIPTION_PLANS.GET,
    errorBinding: getPageSubscriptionPlansError,
    urlParameters: { slug },
    loadingAction: PageSubscriptionPlansActionTypes.GET_PAGE_SUBSCRIPTION_PLANS_REQUEST,
    successBinding: getPageSubscriptionPlansSuccess,
  });

const putPageSubscriptionPlansSuccess = ({ data }): PUT_PAGE_SUBSCRIPTION_PLANS_SUCCESS_ACTION => {
  return actionCreator.createAction(PageSubscriptionPlansActionTypes.PUT_PAGE_SUBSCRIPTION_PLANS_SUCCESS, data);
};

const putPageSubscriptionPlansError = ({ errorData }): PUT_PAGE_SUBSCRIPTION_PLANS_ERROR_ACTION => {
  return actionCreator.createErrorAction(PageSubscriptionPlansActionTypes.PUT_PAGE_SUBSCRIPTION_PLANS_ERROR, errorData);
};
const updatePageSubscriptionPlans = (slug: string, parameters: PageSubcriptionPlanFormValues) =>
  actionCreator.createAsyncAction({
    method: 'put',
    endpoint: endpoints.PAGE_SUBSCRIPTION_PLANS.PUT,
    errorBinding: putPageSubscriptionPlansError,
    urlParameters: { slug },
    parameters: { ...parameters, enabled: true },
    loadingAction: PageSubscriptionPlansActionTypes.PUT_PAGE_SUBSCRIPTION_PLANS_REQUEST,
    successBinding: putPageSubscriptionPlansSuccess,
  });

export const pageSubscriptionPlansActions = {
  fetchPageSubscriptionPlans,
  updatePageSubscriptionPlans,
};
