import ClassNames from '@streamloots/classnames';
import { PaymentMethods } from '@streamloots/streamloots-types';
import { PaymentMethodRadios } from 'components/payment-methods';
import { TranslateInterface } from 'utils/translation';
import theme from './payment-options.scss';

export interface PaymentOptionsProps extends TranslateInterface {
  method: PaymentMethods;
  onChange: (paymentMethod: PaymentMethods) => void;
}

export const PAYMENT_PROCESSORS = [PaymentMethods.PAYPAL, PaymentMethods.STRIPE];
const classNames = ClassNames(theme);

export const PaymentOptions = ({ t, method, onChange }: PaymentOptionsProps): JSX.Element => (
  <div className={classNames('payment-options')}>
    <p>{t('pageSubscription:checkout.secondStep.payWith')}</p>
    <PaymentMethodRadios onChange={onChange} paymentMethods={PAYMENT_PROCESSORS} selectedMethod={method} />
  </div>
);
